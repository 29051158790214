function stats() {

    if ( !! $j('.stats-block').length )  {
        //$j('.stats-block span.stat').each(function(){
		if ( $j('.stats-block').hasClass('started') ) {
			return;
		}

		$j('.akel-design-studio li').each(function(index){
			var el = $j(this);
			if (index == 3 ) {
				setTimeout(function(){
					$j('.accent-tiles').removeClass('start');
				}, index * 250);
			}
		});
        $j('.stats-block b.stat').each(function(index){
            var el = $j(this);
			setTimeout(function(){
				el.parent().parent().removeClass('start');
	            el.countTo({
	                from: 0,
	                to:  el.data('count'),
	                speed: 2500,
	                refreshInterval: 5,
	                formatter: function (value, options) {
	                    value = value.toFixed(options.decimals);
	                    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	                    return value;
	                }
	            });
			}, (1 + index) * 100);
        });
		$j('.stats-block').addClass('started');
    }
}
function statsReset() {

    if ( !! $j('.stats-block').length )  {
		$j('.stats-block.started b.stat').each(function(){
            var el = $j(this);
			var to = el.data('count');
            el.html(to);
            el.parent().parent().addClass('start');
        });
		$j('.stats-block').removeClass('started');
    }
}
