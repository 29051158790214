function designCenter() {

    if ( !! $j('#costSlider').length ) {
        function paymentDisplay(){
            var optCost = parseFloat($j('.price').attr('data-slide-value'));
            var optRate = parseFloat($j('#interestRate').val());
            var multiplier = 0.770943407863297;
            var moPayment = Math.round(((((optCost * (optRate * 0.01)) * 30) + optCost) / 360) * multiplier);
            $j('.price').html('$' + moPayment);
        }
		var amount = Math.round($j('.range-slider').data('amount')) || 120000;
        var costSlider = document.getElementById('costSlider');
        noUiSlider.create(costSlider, {
            animate: true,
            step: 5000,
            start: amount,
            tooltips: [{to: function(value) {return "$" + numberWithCommas(Math.round(parseInt(value)))}}],
            connect: 'lower',
            range: {
                'min': 10000,
                'max': 500000
            },
        });
        costSlider.noUiSlider.set([amount]);
        costSlider.noUiSlider.on('update', function (values, handle) {
            $j('.price').attr('data-slide-value',values[handle]);
            paymentDisplay();
        });
        $j('#interestRate').on('change', function(){
            paymentDisplay();
            var target = $j('#interestRate').val();
            $j('#interestRate > option').removeAttr('selected');
            $j('#interestRate > option[value="'+target+'"]').attr('selected', true);

        });
        function numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        if ( $j(document).width() < 600 ) {
            $j('#interestRate').attr('tabindex', 0);
        }
    }

    $j('.interest-control button.adjust').on('click', function(e){
        e.preventDefault();
        var el = $j(this);
        var target = $j('#interestRate').val();
        $j('#interestRate > option').removeAttr('selected');
        if ( el.hasClass('more') ) {
            if ( target < 9) {
                $j('#interestRate > option[value="'+target+'"]').next('option').attr('selected', true);
            } else {
                $j('#interestRate > option[value="'+target+'"]').attr('selected', true);
            }
        } else {
            if ( target == 5 ) {
                $j('#interestRate > option[value="'+target+'"]').attr('selected', true);
            } else {
                $j('#interestRate > option[value="'+target+'"]').prev('option').attr('selected', true);
            }
        }
        var newval = $j('#interestRate > option:selected').val();
        $j('#interest-select-list button').removeClass('active');
        $j('#interest-select-list button[data-value="'+newval+'"]').addClass('active');
        $j('.interest-toggle>span').text(newval+'%');
        paymentDisplay();
    });
    $j('.interest-toggle').click(function(){
        var el = $j(this);
        if (  $j('.interest-toggle').hasClass('active') ) {
            $j('.interest-select').removeClass('active');
            $j('.interest-options').attr('hidden', true);
            el.removeClass('active');
            el.attr('aria-expanded', false);
            el.html('<span>'+$j('#interestRate').val()+'% </span><i class="fas fa-chevron-down"></i>');
            $j('.interest-toggle').focus();
        } else {
            $j('.interest-options').removeAttr('hidden');
            $j('.interest-select').addClass('active');
            el.addClass('active');
            el.attr('aria-expanded', true);
            el.html('<span>Close </span><i class="fas fa-times"></i>');
            $j('#interest-select-list button.active').focus();
        }
        //$j('.interest-toggle').focus();
    });
    $j('.share-close').click(function(){
        $j('.interest-toggle.active').click();
    });
    $j('.last-item').on('focus',function(){
        $j('.interest-toggle').focus();
    });
    $j('#interest-select-list button').click(function(e){
        var el = $j(this);
        var val = el.data('value');
        $j('#interestRate > option').removeAttr('selected');
        $j('#interestRate > option[value="'+val+'"]').attr('selected', true);
        $j('.interest-toggle.active').click();
        $j('#interest-select-list button').removeClass('active');
        $j('#interest-select-list button[data-value="'+val+'"]').addClass('active');
        paymentDisplay();
    });

    $j(document).bind('keydown', function (event) {
        // left and up
        if (event.keyCode == 33 || event.keyCode == 38 ) {
            //event.preventDefault();
            //$j('.about .nav li.active').prev().find('a').click();
        }
        // right and down
        if (event.keyCode == 34 || event.keyCode == 40 ) {
            //event.preventDefault();
            //$j('.about .nav li.active').next().find('a').click();
        }
        // home
        if (event.keyCode == 36) {
            //event.preventDefault();
        }
        // end
        if (event.keyCode == 35) {
            //event.preventDefault();
        }
    });
}
function navScroller() {
	if ( $j('body').hasClass('scrolling') ) {
		return;
	}
    $j('div.view-region.inview').removeClass('inview');
    var inview = $j('div.view-region:in-viewport:first').find('.anchor>a').attr('id');
    $j('div.view-region:in-viewport:first').addClass('inview');
    $j('.design-studio-nav>li.active,.faq-nav>ul>li.active').removeClass('active');
    $j('a[href="#'+inview+'"]').parent().addClass('active');
    $j('#active-sub-nav span').html($j('a[href="#'+inview+'"]').html());
}
