function akelCookies() {

    if ( !! $j('.ppauto').length ) {
        var
        ppbtn = $j('.ppauto'),
        expires = ppbtn.data('expires'),
        cookie = ppbtn.data('cookie'),
        cookie_val = Number(readCookie(cookie)),
        visits =  Number(ppbtn.data('visits')),
        ran = 10;
        if ( readCookie(cookie) ) {
            if (cookie_val == visits) {
                createCookie(cookie, ran, expires);
                setTimeout(function() {
                    $j('.ppauto').click();
                }, 600);
            } else {
                if (cookie_val < visits) {
                    createCookie(cookie, cookie_val + 1, expires);
                }
            }
        } else {
            if ( visits == 0 ) {
                createCookie(cookie, ran, expires);
                setTimeout(function() {
                    $j('.ppauto').click();
                }, 600);
            } else {
                createCookie(cookie, 1, expires);
            }
        }
    }

    if ( !! $j('.btn-alert-trigger').length ) {
        var el = $j('.btn-alert-trigger');
        var cookie = 'covid-19-notice';
        if ( readCookie(cookie) ) {
            return;
        }
        el.removeClass('loading');
        el.click(function(){
            el.addClass('loading');
            createCookie(cookie, 1, .04);
        });
    }

    if ( !! $j('.header-notice').length ) {
        var cookie = 'solana-bay';
        if ( ! readCookie(cookie) ) {
            setTimeout(function() {
                $j('.header-notice').slideDown(500, 'easeInOutExpo');
                //createCookie(cookie, 1, 1);
            },500);
        }
        $j('.header-notice button.close').click(function(e){
            var el = $j(this);
            $j('.header-notice').slideUp(500, 'easeInOutExpo');
            createCookie(cookie, 1, 7);
            e.preventDefault();
        });
        $j(document).on('keydown', function(e) {
            if (e.key == "Escape") {
                $j('.header-notice button.close').click();
            }
        });
    }
}
