function tracking() {

    window.dataLayer = window.dataLayer || [];
    var community_name = $j('button[type="submit"].working').parent().parent().find('input[name="community_name"]').val();
    var floorplan_name = $j('button[type="submit"].working').parent().find('input[name="floorplan"]').val();

    $j('body.single-event .contact-form .submit button').click(function(){
        window.dataLayer.push({
            'event': 'clickRSVP',
            'community': community,
            'referer': referer,
            'pageurl': page_url,
        });
    });

    $j('a.btn-schedule').click(function(){
        window.dataLayer.push({
            'event': 'clickSchedule',
            'community': community,
            'referer': referer,
            'pageurl': page_url,
        });
    });
    $j('a.btn-banner-start').click(function(){
        window.dataLayer.push({
            'event': 'clickSubscribeMobile',
            'community': community,
            'referer': referer,
            'pageurl': page_url,
        });
    });

    $j('a[data-action]').click(function(){
        var el = $j(this);
        var action = el.data('action');
        var dcommunity = el.data('community') || community;
        var dfloorplan = el.data('floorplan') || floorplan;
        var caption = el.data('caption');
        var page_url = page_url;
        var pushdata = {
            'event': action,
            'community': dcommunity,
            'floorplan': dfloorplan,
            'title': caption,
            'referer': referer,
            'pageurl': page_url,
        };
        window.dataLayer.push(pushdata);
    });

    $j('a.btn-call').click(function(){
        window.dataLayer.push({
            'event': 'clickCall',
            'community': community,
            'referer': referer,
            'pageurl': page_url,
        });
    });

    $j('a.btn-email').click(function(){
        window.dataLayer.push({
            'event': 'clickEmail',
            'community': community,
            'referer': referer,
            'pageurl': page_url,
        });
    });

    $j('a[data-event]').click(function(){
        window.dataLayer.push({
            'event': 'clickRSVP',
            'community': community,
            'referer': referer,
            'pageurl': page_url,
        });
    });

    $j('.btn-floorplan-download').click(function(){
        var el = $j(this);
        var fp = el.data('floorplan');
        floorplan_name = fp || floorplan;
        floorplan_name = floorplan_name.toUpperCase();
        log(floorplan_name);
        window.dataLayer.push({
            'event': 'downloadFloorplan',
            'community': community,
            'floorplan': floorplan_name,
            'referer': referer,
            'pageurl': page_url,
        });
    });

    $j('.btn-video-virtual-tour').click(function(){
        var el = $j(this);
        var fp = el.data('floorplan');
        floorplan_name = fp || floorplan;
        floorplan_name = floorplan_name.toUpperCase();
        window.dataLayer.push({
            'event': 'videoVirtualTour',
            'community': community,
            'floorplan': floorplan_name,
            'referer': referer,
            'pageurl': page_url,
        });
    });

    $j('.btn-video-model-tour').click(function(){
        var el = $j(this);
        var fp = el.data('floorplan');
        floorplan_name = fp || floorplan;
        floorplan_name = floorplan_name.toUpperCase();
        window.dataLayer.push({
            'event': 'videoModelTour',
            'community': community,
            'floorplan': floorplan_name,
            'referer': referer,
            'pageurl': page_url,
        });
    });

    $j('.btn-video-community').click(function(){
        window.dataLayer.push({
            'event': 'videoCommunityTour',
            'community': community,
            'referer': referer,
            'pageurl': page_url,
        });
    });

    $j('.btn-faq-video').click(function(){
        window.dataLayer.push({
            'event': 'videoFAQ',
            'community': community,
            'referer': referer,
            'pageurl': page_url,
        });
    });

    $j(document).on('click', '.btn-brochure-download', function(){
        window.dataLayer.push({
            'event': 'downloadBrochure',
            'community': community,
            'referer': referer,
            'pageurl': page_url,
        });
    });

    $j('form.subscribe button').click(function(){
        window.dataLayer.push({
            'event': 'clickSubscribe',
            'community': community,
            'referer': referer,
            'pageurl': page_url,
        });
	});

}
