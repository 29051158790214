function locationMap() {

    if ( ! $j('.poi-map').length ) {
        return;
    }

    $ = jQuery.noConflict(),

    function new_map( $el ) {

        // var
        var $markers = $el.find('.marker');


        // vars
        var args = {
            zoom        : 1,
            center        : new google.maps.LatLng(0, 0),
            mapTypeId    : google.maps.MapTypeId.ROADMAP
        };


        // create map
        var map = new google.maps.Map( $el[0], args);


        // add a markers reference
        map.markers = [];


        // add markers
        $markers.each(function(){

            add_marker( $(this), map );

        });


        // center map
        center_map( map );

        // return
        return map;

    }


    function add_marker( $marker, map ) {

        // var
        var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

        // create marker
        var marker = new google.maps.Marker({
            position    : latlng,
            map            : map
        });

        // add to array
        map.markers.push( marker );

        // if marker contains HTML, add it to an infoWindow
        if( $marker.html() )
        {
            // create info window
            var infowindow = new google.maps.InfoWindow({
                content        : $marker.html()
            });

            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function() {
                infowindow.open( map, marker );

            });
        }

    }


    function center_map( map ) {

        // vars
        var bounds = new google.maps.LatLngBounds();

        // loop through all markers and create bounds
        $.each( map.markers, function( i, marker ){

            var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

            bounds.extend( latlng );

        });

        // only 1 marker?
        if( map.markers.length == 1 )
        {
            // set center of map
            map.setCenter( bounds.getCenter() );
            map.setZoom( 16 );
        }
        else
        {
            // fit to bounds
            map.fitBounds( bounds );
        }

    }

    $('.poi-map').each(function(){

        var map_div = $(this).find('#google-poi-map');

        var location = new google.maps.LatLng(map_div.data('lat'), map_div.data('lng'));

        var map = new google.maps.Map(map_div.get(0),{
            zoom : 14,
            center : location,
            scrollwheel: false
        });

        window.all_markers = [];
        window.all_infowindows = [];
        var bounds = new google.maps.LatLngBounds();

        var marker_counter = 0;

        $(this).find('.categories .category').each(function(){

            var category = $(this);
            var color = category.find('h3').data('color');

            category.find('ul li').each(function(){

                var place = $(this);

                var circle = {
                    path: google.maps.SymbolPath.CIRCLE,
                    fillColor: color,
                    fillOpacity: 1,
                    scale: 7,
                    strokeColor: '#FFFFFF',
                    strokeWeight: 1
                };

                var marker_location = new google.maps.LatLng(place.data('lat'), place.data('lng'));

                bounds.extend(marker_location);

                var marker_params = {
                    position : marker_location,
                    map : map,
                    title : place.data('name'),
                    icon : circle,
                    optimized : false,
                    zIndex : 1,
                }

                var marker = new google.maps.Marker(marker_params);

                var content = '<div class="in-map-place-content">';
                content += '<h3>' + place.text() + '</h3>';
                content += '</div>';

                var infowindow = new google.maps.InfoWindow({
                    content : content
                })

                google.maps.event.addListener(marker, 'click', function() {
                    for (var i = all_infowindows.length - 1; i >= 0; i--) {
                        all_infowindows[i].close();
                    };
                    infowindow.open(map, marker);
                });

                all_infowindows.push(infowindow);
                all_markers.push(marker);

                place.data('marker_id', marker_counter);

                place.on('click', function(){
                    var el = $j(this);
                    var marker = all_markers[$(this).data('marker_id')];
                    map.setCenter(marker.getPosition());
                    new google.maps.event.trigger(marker, 'click');


                    $j('.category ul li.active').each(function(){
                        var el = $j(this);
                        el.removeClass('active');
                    });

                    el.addClass('active');
                })

                marker_counter++;

            })
        })

        var main_marker = new google.maps.Marker({
            position : location,
            map : map,
            title : 'Map Pin',
            icon : '/wp-content/themes/akel/assets/img/marker.svg',
            zIndex : 10,
            optimized : false
        });

        var main_infowindow = new google.maps.InfoWindow({
            content : '<h3>Akel Homes</h3>'
        })

        google.maps.event.addListener(main_marker, 'click', function() {
            main_infowindow.open(map, main_marker);
        });

        google.maps.event.addListener(map, 'tilesloaded', function(evt){
            $('.poi-map').find("img").each(function(i, eimg){
                if(!eimg.alt || eimg.alt ===""){
                    eimg.alt = "";
                }
            });
        });
        $('.poi-map').find("img").each(function(i, eimg){
            if(!eimg.alt || eimg.alt ===""){
                eimg.alt = "";
            }
        });
        map.fitBounds(bounds);

    });

}
