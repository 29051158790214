function tabNav() {

    $j('a.mobile-nav.next').click(function(event) {
        var el = $j(this);
        var tabs = el.data('tabs');
        $j('.'+tabs+'-nav').find('a.active').parent().next().find('a').click();
    });

    $j('a.mobile-nav.prev').click(function(event) {
        var el = $j(this);
        var tabs = el.data('tabs');
        $j('.'+tabs+'-nav').find('a.active').parent().prev().find('a').click();
    });

    if ( !! $j('.media-nav').length ) {

        $j('.media-nav li a').click(function(event) {
            event.preventDefault();
            var el = $j(this);
            var tabs = 'media-tab';
            var target = el.attr('href');
			if ( el.hasClass('active') ) {
				return;
			}
            $j('.media-nav li a').removeClass('active');
            el.addClass('active');
            $j('div.'+tabs).hide();
			$j(target).fadeIn(300);
			bLazy.revalidate();
		});
	}

    if ( !! $j('.tab-group').length ) {

        $j('h3 button.title').click(function(event) {
            event.preventDefault();
            var tabs = $j(this).data('tabs');
			var el = $j(this).parent().parent().parent();
			if (el.hasClass('active')) {
				el.find('div.tab-content').stop(true, false).slideUp(600, 'easeInOutExpo');
				el.find('i.fal').addClass('fa-plus').removeClass('fa-minus');
				el.removeClass('active');
			} else {
				$j('div.'+tabs).removeClass('active').find('i.fal').removeClass('fa-minus').addClass('fa-plus');
				$j('div.'+tabs+' div.tab-content').slideUp(600, 'easeInOutExpo');
				el.find('div.tab-content').removeClass('hidden-content').stop(true, false).slideDown(600, 'easeInOutExpo');
				el.find('i.fal').removeClass('fa-plus').addClass('fa-minus');
				el.addClass('active');
			}
			bLazy.revalidate();
			return;
		});
	}

	$j('.nav-bar .head button.nav').click(function(){
        var el = $j(this);
        var tabs = el.data('tabs');
		var next = $j('.nav-bar.nav-'+tabs).find('li.active').next().find('a');
		var prev = $j('.nav-bar.nav-'+tabs).find('li.active').prev().find('a');
		if ( el.hasClass('next')) {
			if ( next.length ) {
				next.click();
			} else {
				 $j('.nav-bar.nav-'+tabs+' li:first-child a').click();
			}
		} else {
			if ( prev.length ) {
				prev.click();
			} else {
				 $j('.nav-bar.nav-'+tabs+' li:last-child a').click();
			}
		}
		$j('.nav-bar-nav.nav-'+tabs).removeClass('open');
	});

	$j('.nav-bar .head button.title').click(function(){
        var el = $j(this);
        var tabs = el.data('tabs');
		var nav = $j('.nav-bar.nav-'+tabs);
		if ( nav.hasClass('open') ) {
			nav.removeClass('open')
			return;
		}
		nav.addClass('open');
	});

    if ( $j('.nav-bar li a').length ) {
        $j('.nav-bar:not(.tabs-nav) li a').click(function(event) {
            event.preventDefault();
            var el = $j(this);
            var tabs = el.data('tabs');
            var target = el.attr('href');
			var nav = $j('.nav-bar.nav-'+tabs);
			if ( el.parent().hasClass('active') ) {
				return;
			}
            el.parent().parent().find('.active').removeClass('active');
            el.parent().addClass('active');
			$j('.nav-bar.nav-'+tabs).find('button.title>span.text').html(el.html());
            $j('body').addClass('scrolled scrolling');
            var destination = $j(target).offset().top || 0;
            $j("html:not(:animated),body:not(:animated)").animate({
                scrollTop: destination
            }, 1200, 'easeInOutExpo', function() {
				if ( ! $j('body.admin-bar').length ) {
					window.location.hash = target;
				}
                setTimeout(function(){
                    $j('body').addClass('scrolled');
                    $j('body').removeClass('scrolling');
            		$j(target).parent().parent().find('.active').removeClass('active');
            		$j(target).parent().addClass('active');
                }, 100);
            });

			if ( nav.hasClass('open') ) {
				nav.removeClass('open');
			}
            return true;
			return;
		});
	}

    if ( $j('.tabs-nav li a').length ) {
        $j('.tabs-nav li a').click(function(event) {
            event.preventDefault();
            var el = $j(this);
            var tabs = el.data('tabs');
            var index = el.parent().index() + 1;
			var max = el.parent().parent().children().length;
            var target = el.attr('href');
            var text = el.text();
            log(tabs);
            log(text);
			if ( el.parent().hasClass('active') ) {
				return;
			}
            el.parent().parent().find('.active').removeClass('active');
            el.parent().addClass('active');
            $j('div.tabs-'+tabs).addClass('hidden-panel');
            $j('div.tabs-'+tabs).addClass('hidden-panel');
            //$j('button.title').html(el.html() + ' &nbsp;<span class="count">('+index+' / '+max+')</span>');
            $j('div.nav-'+tabs+' button.title>span.text').html(el.html());
			$j(target).fadeTo(1,0);
            $j(target).removeClass('hidden-panel');
			$j(target).fadeTo(400,1);
			bLazy.revalidate();
			window.location.href = '#nav-'+tabs;
			setTimeout(function() {
				scroll();
				$j('body').addClass('scrolled');
			}, 100);
			$j('.tabs-nav.nav-'+tabs).removeClass('open');
			el.focus();
		});
	}

    if ( $j('.tab-nav li a').length ) {

        $j('.tab-toggle').click(function(event) {
            var el = $j(this);
            el.parent().toggleClass('hover');
        });

        $j('.tab-nav li a').click(function(event) {

            event.preventDefault();

            var el = $j(this);
            var tabs = el.data('tabs');
            var target = el.data('target');
            var text = el.text();

            $j('.tab-nav-mobile>li.hover').removeClass('hover');

            el.parent().parent().parent().find('button.active>span').text(text);

            el.parent().parent().find('a.active').removeClass('active');
            el.addClass('active');

            $j('div.'+tabs+'.tab').addClass('hidden-panel');
            $j('#'+target).removeClass('hidden-panel');

            $j('a.'+tabs+'.mobile-nav').removeClass('disabled');

            if ( el.hasClass('first') ) {
                $j('a.'+tabs+'.mobile-nav.prev').addClass('disabled');
            }
            if ( el.hasClass('last') ) {
                $j('a.'+tabs+'.mobile-nav.next').addClass('disabled');
            }

            bLazy.revalidate();
            //var activeTab = el.attr('href');
            //window.location.hash = activeTab;
            //$j('.tab.'+tabs).hide();
            //$j('#'+target).fadeIn(300);
            return false;

        });

    }

    if ( $j('.features .nav-mobile').length ) {
        $j('.feature-content').removeClass('hidden-panel');
        $j('.feature-content').slideUp(1);
        $j('.features .see-all').click(function(event) {
            event.preventDefault();
            var el = $j(this);
            el.parent().removeClass('start');
            el.remove();
        });
        $j('.features .nav-mobile').click(function(event) {
            event.preventDefault();
            var el = $j(this);
            var img = el.data('img');
            var target = el.data('target');
            var text = el.text();
            if ( el.hasClass('active') ) {
                el.find('.accent').removeClass('fa-minus').addClass('fa-plus');
                el.removeClass('active').next('.feature-content').slideUp(300);
                return;
            }
            $j('.feature-content').slideUp(300);
            $j('.nav-mobile.active').removeClass('active');
            $j('.nav-mobile').find('.accent').removeClass('fa-minus').addClass('fa-plus');
            el.find('.accent').removeClass('fa-plus').addClass('fa-minus');
            el.addClass('active').next('.feature-content').slideDown(300);
            //$j('.features-header').css({'background-image' : 'url('+img+')'});
            return;
        });

        $j('.features .nav-mobile:first').click();
        var bg = $j('.features .nav-mobile').data('img');
        $j('.features-header').css({'background-image' : 'url('+bg+')'});

        $j('.nav-desktop li>a').click(function(event) {

            event.preventDefault();

            var el = $j(this);
            var img = el.data('img');
            var target = el.data('target');
            var text = el.text();

            if ( el.hasClass('active') ) {
                el.find('.accent').removeClass('fa-minus').addClass('fa-plus');
                el.removeClass('active').next('.feature-content').slideUp(300);
                return;
            }

            $j('.feature-content').fadeOut(0);
            $j('.nav-desktop li>a.active').removeClass('active');
            $j('.nav-desktop li>a').find('.accent').removeClass('fa-minus').addClass('fa-plus');
            el.find('.accent').removeClass('fa-plus').addClass('fa-minus');
            el.addClass('active').next('.feature-content').slideDown(300);
            //$j('.features-header').css({'background-image' : 'url('+img+')'});
            $j('#'+target).find('.feature-content').fadeIn(300);
            return;

        });

    }

}
