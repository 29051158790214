function mcalc() {

    (function (window, $) {

        $(function() {
            if( ! $('.akel-mortgage-calculator').length) {
                return;
            }
            var
            mcprice,
            mcdown,
            mcpercent,
            mcrate,
            mcpayment;
            $('[data-field="purchasePrice"]').on('change, blur, keyup', function(e){
                e.preventDefault();
                var val = calcNumber('purchasePrice');
                val = '$'+commaSeparateNumber(val);
                $(this).val(val);
                buildDownPayment();
                buildMonthlyPayment();
            })
            $('[data-field="downPercent"]').change(function(e){
                buildDownPayment();
                buildMonthlyPayment();
            })
            $('[data-field="interestRate"]').change(function(e){
                buildMonthlyPayment();
            })
            $('.akel-mortgage-calculator').submit(function(e){
                e.preventDefault();
                $('.akel-mortgage-calculator input, .akel-mortgage-calculator select').each(function(e){
                    var el = $(this);
                    var reset = el.data('default');
                    el.val(reset);
                });
                buildDownPayment();
                buildMonthlyPayment();
            })
            $('.akel-mortgage-calculator').submit();

            function buildMonthlyPayment() {
                mcprice = calcNumber('purchasePrice');
                mcrate = calcNumber('interestRate');
                mcdown = calcNumber('downPayment');
                mcpayment = parseFloat(calc((mcprice - mcdown),mcrate)).toFixed(2);
                if(Math.sign(mcpayment) == -1){mcpayment = 0}
                $('[data-bind="monthlyPayment"]').text('$' + commaSeparateNumber(mcpayment));
            }
            function buildDownPayment() {
                mcprice = calcNumber('purchasePrice');
                mcpercent = calcNumber('downPercent');
                var mcdowncalc = parseFloat(mcprice * (mcpercent / 100) ).toFixed(0);
                $('[data-field="downPayment"]').val(numberToCurrency(mcdowncalc));
            }
            function calc(mortgage, rate) {
                rate = rate / 12;
                var PE = 30 * 12
                var payment = (mortgage * rate) / (1 - Math.pow(1 + rate, -PE));
                return Math.ceil(payment);
            }
            function commaSeparateNumber(val){
                while (/(\d+)(\d{3})/.test(val.toString())) {
                    val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
                }
                return val;
            }
            function numberToCurrency(val) {
                return '$' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
            function calcNumber(field) {
                var val = $('[data-field="'+field+'"]').val();
                val = parseFloat(val.replace(/\$/g,'').replace(/\,/g,'').replace(/\%/g,''));
                if ( isNaN(val) ) {
                    val = 0;
                }
                return val;
            }
            return;
            var percentSlider = document.getElementById('akel-mc-slider');
            noUiSlider.create(percentSlider, {
                animate: true,
                value: 0,
                step: .5,
                start: 0,
                range: {
                    'min': 0,
                    'max': 99.5
                },
            });

        });

    })(window, jQuery);

}