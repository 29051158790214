function swipers() {

    if ( $j('.homes-slider.col-3').length ) {
        $j('.hs-wrap.preload').removeClass('preload');
        if ( $j('.homes-slider.col-3').hasClass('rand') ) {
            let sliderContainer = document.querySelector('.homes-slider.col-3');
            shuffleSlides(sliderContainer);
        }
        var slider1 = tns({
            'container': '.homes-slider.col-3',
            'items': 1,
            "gutter": 20,
            'slideBy': 'page',
            'mouseDrag': true,
            'swipeAngle': false,
            'navPosition': 'bottom',
            'loop': true,
            'responsive': {
                '600': {
                'items': 2,
                "gutter": 20,
                },
                '980': {
                'items': 3,
                "gutter": 20,
                },
                '1300': {
                    "gutter": 20,
                }
            },
        });
        slider1.events.on('transitionEnd', function(){
            bLazy.revalidate();
        });
        slider1.events.on('newBreakpointStart', function(){
            bLazy.revalidate();
        });
    }
    if ( $j('.homes-slider.col-1').length ) {
        $j('.hs-wrap.preload').removeClass('preload');
        if ( $j('.homes-slider.col-1').hasClass('rand') ) {
            let sliderContainer = document.querySelector('.homes-slider.col-1');
            shuffleSlides(sliderContainer);
        }
        var slider2 = tns({
            'container': '.homes-slider.col-1',
            'items': 1,
            "gutter": 20,
            'slideBy': 'page',
            'mouseDrag': true,
            'swipeAngle': false,
            'navPosition': 'bottom',
            'loop': true
        });
        slider2.events.on('transitionEnd', function(){
            bLazy.revalidate();
        });
        slider2.events.on('newBreakpointStart', function(){
            bLazy.revalidate();
        });
    }

    if ( !! $j('.testimonial-videos').length ) {
        $j('.testimonial-videos.preload').removeClass('preload');
        if ( $j('.testimonial-videos').hasClass('rand') ) {
            let sliderContainer = document.querySelector('.testimonial-videos');
            shuffleSlides(sliderContainer);
        }
        var slider3 = tns({
            'container': '.akel-video-testimonials',
            'items': 1,
            'arrowKeys': true,
            //'mouseDrag': true,
            'loop': true,
            //'slideBy': 'page',
            //'edgePadding': 50,
            //'fixedWidth': 1200,
            //'center': true,
            //'gutter': 20,
            'responsive': {
                '320': {
                'items': 1
                },
                '600': {
                'items': 1
                }
            },
        });
        slider3.events.on('transitionEnd', function(){
            bLazy.revalidate();
        });
        slider3.events.on('newBreakpointStart', function(){
            bLazy.revalidate();
        });
    }

    if ( $j('.videos-wrap').length ) {
        $j('.videos-wrap.preload').removeClass('preload');
        $j('.video-slide').hover(
            function() {
                if (window.matchMedia("(min-width: 980px)").matches) {
                    var video = $j(this).find('video').get(0);
                    if (video && video.readyState === 4) {
                        video.play(); // Play video on hover if it's loaded
                    }
                }
            },
            function() {
                if (window.matchMedia("(min-width: 981px)").matches) {
                    var video = $j(this).find('video').get(0);
                    if (video) {
                        //video.currentTime = 0;
                        video.pause(); // Pause video on leave
                    }
                }
            }
        );
    }
    if ( $j('.news-slider.col-3').length ) {
        $j('.news-wrap.preload').removeClass('preload');
        if ( $j('.news-slider.col-3').hasClass('rand') ) {
            let sliderContainer = document.querySelector('.news-slider.col-3');
            shuffleSlides(sliderContainer);
        }
        var slider4 = tns({
            'container': '.news-slider.col-3',
            'items': 1,
            "gutter": 20,
            'slideBy': 'page',
            'mouseDrag': true,
            'swipeAngle': false,
            'navPosition': 'bottom',
            'loop': true,
            'responsive': {
                '750': {
	                'items': 2,
	                "gutter": 20,
                },
                '1024': {
	                'items': 3,
	                "gutter": 20,
                }
            },
        });
        slider4.events.on('transitionEnd', function(){
            bLazy.revalidate();
        });
        slider4.events.on('newBreakpointStart', function(){
            bLazy.revalidate();
        });
    }
    if ( $j('.news-slider.col-2').length ) {
        $j('.news-wrap.preload').removeClass('preload');
        if ( $j('.news-slider.col-2').hasClass('rand') ) {
            let sliderContainer = document.querySelector('.news-slider.col-2');
            shuffleSlides(sliderContainer);
        }
        var slider5 = tns({
            'container': '.news-slider.col-2',
            'items': 1,
            "gutter": 20,
            'slideBy': 'page',
            'mouseDrag': true,
            'swipeAngle': false,
            'navPosition': 'bottom',
            'loop': true,
            'responsive': {
                '750': {
                'items': 2,
                "gutter": 20,
                },
                '1000': {
                'items': 1,
                "gutter": 20,
                },
                '1200': {
                	'items': 2,
                    "gutter": 20,
                }
            },
        });
        slider5.events.on('transitionEnd', function(){
            bLazy.revalidate();
        });
        slider5.events.on('newBreakpointStart', function(){
            bLazy.revalidate();
        });
    }
}
function activeVideo() {
    if (window.matchMedia("(max-width: 980px)").matches) {
        var activeVideo = $j('.videos-wrap .tns-slide-active:in-viewport:first').find('video').get(0);
        document.querySelectorAll('video').forEach(function (video) {
            if (video !== activeVideo) {
                video.pause();
            }
        });
        if (activeVideo && activeVideo.readyState === 4) {
            activeVideo.play();
        }
    }
}
