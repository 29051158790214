var bLazy = new Blazy({
    success: function(el) {
        setTimeout(function() {
            var parent = el.parentNode;
            var loader = parent.querySelector('.img-loader');
            if (typeof(loader) != 'undefined' && loader != null && typeof(loader.parentNode) != 'undefined' && loader.parentNode != null) {
	            loader.style.opacity = 0;
	            el.classList.remove("preload");
	            setTimeout(function() {
					if (typeof(loader) != 'undefined' && loader != null && typeof(loader.parentNode) != 'undefined' && loader.parentNode != null) {
                    	loader.parentNode.removeChild(loader);
					}
	            }, 750);
			}
        }, getRandomInt('100', '600'));
    },
    error: function(el, msg) {
        if (msg === 'missing') {
            //log(ele, msg);
        } else if (msg === 'invalid') {
            //log(ele, msg);
        }
    }
});

function loader() {

    if ( !! $j('.welcome-content').length ) {
        setTimeout(function(){
            $j('.welcome-content.preload').find('.img-loader').fadeOut(300);
        }, 300);
    }

    setTimeout(function() {
        $j('.hero h2, .hero h1').addClass('loaded');
    }, 400);

    if ( !! $j('.main-content').length ) {
        $j('.main-content').fadeTo(250,1);
    }
    if ( !! $j('div.loader').length ) {
        setTimeout(function(){
            $j('div.loader:not(.slow)').fadeOut(loader_fadeout);
        },loader_delay);
        setTimeout(function(){
            $j('div.loader.slow').fadeOut(loader_fadeout);
        },loader_delay + 750);
    }
    $j('.tablet-actions').addClass('loaded');
    $j('#nav').addClass('loaded');
    $j('.qwr').fadeTo(300,1);
    $j('.preload').on('load', function() {
        var el = jQuery(this);
        el.parent().find('.img-loader').fadeOut(300);
        setTimeout(function() {
            el.parent().removeClass('loading');
            el.next().fadeOut(300);

        }, getRandomInt('100', '600') );
        setTimeout(function() {
            el.next().remove();
        }, 3000);
    });
    if ( !! $j('div.curtain.current').length ) {
		if ( curtain == 'test' ) {
			$j('.curtain').click(function(){
				$j(this).fadeOut('300');
			});
            return;
		}
		$j('body').addClass('curtain-on');
		createCookie('revisit', true, 1);
		setTimeout(function() {
			$j('.curtain.current .loader-bar').addClass('loaded');
		}, 100);
		setTimeout(function() {
			$j('.curtain.current .loader-bar').fadeTo(300, 0);
		}, 1200);
		setTimeout(function() {
			$j('.curtain.current .content').fadeTo(300, 0);
			$j('.curtain.current .loader-bar').addClass('start');
		}, 1400);
		setTimeout(function() {
			$j('.curtain').fadeOut(600);
		}, 1600);
		setTimeout(function() {
			$j('.curtain.current .loader-bar').removeClass('loaded animate');
			$j('body').removeClass('curtain-on');
		}, 2300);
	}
    bLazy.revalidate();
    setTimeout(function() {
        bLazy.revalidate();
    }, 7500);
    $j('.hero.multi').css({
        'height': parseInt($j('.hero').height()),
        'overflow' : 'hidden'
    });
    $j('.ss').cycle();
}
