function countdown() {

    if ( !! $j('#akel-countdown').length )  {

        var defaultOptions = {
            'timezone': "America/New_York"
        };
        $j("#akel-countdown").each(function () {
            var el = $j(this);
            var options = $j.extend({}, defaultOptions, el.data());
            var time = moment.tz(options.time, options.timezone);
            //var format = " %-D day%!D, %H:%M:%S";
            var format = "%D <b>:</b> %H <b>:</b> %M <b>:</b> %S";
            el.countdown(time.toDate())
                .on('update.countdown', function (event) {
                    $j(this).html(event.strftime(format));
                })
                .on('finish.countdown', function (event) {
                    $j(this).html('00 <b>:</b> 00 <b>:</b> 00 <b>:</b> 00');
                    $j(this).after('<a href="">Start Live Stream</a>');
                });
        });

    }

};
