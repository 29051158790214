function walkIn() {

    if ( !! $j('.tml-label').length ) {
        var u = getUrlVars()['u'];
        var k = getUrlVars()['k'];
        if ( u ) {
            $j('div.tml-login').find('input#user_login').val(u);
        }
        if ( k ) {
            $j('div.tml-login').find('input#user_pass').val(k);
        }
        if ( u && k ) {
            $j('div.tml-login form button[name="submit"]').click();
        }
    }

    if ( !! $j('.btn-reg').length ) {
        $j('.btn-reg').click(function(){
            var el = $j(this);
            var type = el.data('type');

            $j('.hiddenRow.'+type).removeClass('hiddenRow');
        });
    }

}
