function faqs() {
    if ( $j('div.faqs-list').length ) {
		$j('div.faqs-list h3 button').click(function (e) {
			e.preventDefault();
			var el = $j(this).parent().parent();
			if (el.hasClass('active')) {
				el.find('div.answer').stop(true, false).slideUp(600, 'easeInOutExpo');
				el.find('i.fal').addClass('fa-plus').removeClass('fa-minus');
				el.removeClass('active');
			} else {
				el.find('div.answer').stop(true, false).slideDown(600, 'easeInOutExpo');
				el.find('i.fal').removeClass('fa-plus').addClass('fa-minus');
				el.addClass('active');
			}
		});
		setTimeout(() => {
			$j('div.faqs-list h3:first button').click();
		}, 500);
    }
}
