function booking() {
    (function (window, $) {

        $('#schedule-appointment-time').val('10:00 AM');
        function updateTourHTML() {
            var time = $('#schedule-appointment-time').val() || '10:00 AM';
            var date = $('#schedule-appointment-date').val();
            var type = $('#schedule-appointment-type').val() || 'In-Person';
            // Use string template literals to inject values into HTML string
            var htmlString = `<p class="clear w100">
                <button class="btn-appointment-back btn btn-primary-outline btn-sm right"><i class="fas fa-chevron-left"></i> Go Back</button>
                <strong class="hide">Date:</strong> <strong>${date}</strong><br>
                <strong class="hide">Time:</strong> <strong>${time}</strong><br>
                <strong class="hide">Tour Type:</strong>
                <strong>${type}</strong><br></p>`;
            $('.selected-appointment-info').html(htmlString);
        }

        $(function() {
            if( ! $('.akel-booking-date.loading').length) {
                return;
            }
            $('.akel-booking-type button').click(function(event){
                event.preventDefault();
                var el = $j(this);
                var type = el.data('booking-type');
                $('.akel-booking-type button').removeClass('active');
                el.addClass('active');
                $('#schedule-appointment-type').val(type);
                if ( $('.tours-requested.step-2').hasClass('active') ) {
                    $('.tours-requested.step-1').slideDown(750,'easeOutCirc');
                    $('div.text.step-1').slideDown(750,'easeOutCirc');
                    $('.tours-requested.step-2').slideUp(750,'easeOutCirc');
                    $('.tours-requested.step-2').removeClass('active');
                } else {
                    $('.tours-requested.step-1').slideUp(750,'easeOutCirc');
                    $('div.text.step-1').slideUp(750,'easeOutCirc');
                    $('.tours-requested.step-2').slideDown(750,'easeOutCirc');
                    $('.tours-requested.step-2').addClass('active');
                }
                updateTourHTML();
            });

            $(document).on('click','.btn-appointment-back',function() {
                $('.akel-booking-type button.active').click();
            });

            $('.time-option').click(function() {
                var el = $(this);
                var text = el.text();
                $('#schedule-appointment-time').val(text);
                updateTourHTML();
                if (!$(this).hasClass('active')) {
                    $('.time-option').removeClass('active');
                    $(this).addClass('active');
                } else {
                    return;
                    $(this).removeClass('active');
                }
            });

            $('.akel-booking-date.loading').removeClass('loading');

            var slider = tns({
                'container': '.akel-booking-date-slider',
                'items': 1,
                //'slideBy': 'page',
                'mouseDrag': true,
                'swipeAngle': false,
                'loop': false,
                'responsive': {
                    '320': {
                    'items': 2
                    },
                    '600': {
                    'items': 3
                    }
                },
            });

            $('.tns-controls button').click(function(e){
                e.preventDefault();
                var el = $(this);
                var direction = el.data('controls');
                $('.tns-item a.active').removeClass('active');
                if ( direction == 'next' ) {
                    $('.akel-booking-date .tns-slide-active').first().next().next().find('a').trigger('click');
                } else {
                    $('.akel-booking-date .tns-slide-active').first().find('a').trigger('click');
                }
            });

            $('.akel-booking-type.calendy a').click(function(event){
                event.preventDefault();
                var el = $j(this);
                var url = $j('.akel-booking-type').data('url');
                var type = el.data('booking-type');
                var date = $('.akel-booking-date a.active').data('date');
                var month = $('.akel-booking-date a.active').data('month');
                var url = url+'?month='+month+'&a2='+type+'&a3=1&date='+date;
                //document.location.href=( url );
                window.open(url);
            });

            $('.akel-booking-date div.tns-item>a').click(function(event){
                event.preventDefault();
                var el = $j(this);
                var date = el.data('date');
                $('.akel-booking-date a.active').removeClass('active');
                el.addClass('active');
                $('#schedule-appointment-date').val(date);
                updateTourHTML();
                // below is for calendy
                var url = $j('.akel-booking-type.calendy').data('url');
                var date = $('.akel-booking-date a.active').data('date');
                var month = $('.akel-booking-date a.active').data('month');
                var virtualurl = url+'?month='+month+'&a2=2&a3=1&date='+date;
                var personurl = url+'?month='+month+'&a2=1&a3=1&date='+date;
                $('.akel-booking-type.calendy a[data-booking-type="2"]').attr('href', virtualurl);
                $('.akel-booking-type.calendy a[data-booking-type="1"]').attr('href', personurl);
                // http://ganlanyuan.github.io/tiny-slider/demo/
            });

            $('.akel-booking-date div.tns-item a.start').trigger('click').removeClass('start');

        });

    })(window, jQuery);
}
