//"use strict";

var

$j = jQuery.noConflict(),

resizerfn = null,
scrollerfn = null,

_rps = 100, // resizes per second
_sps = 100, // scrolls per second
scrolledtop = 300, // top offset to determine scroll

loader_delay = 100,
loader_fadeout = 300,

$default; // don't forget ';'
