function singleFloorplan() {

	$j('.qmi-header .cycle-slideshow').cycle('pause');
	$j(document).on('mouseenter', '.quick-move-in>li', function() {
	    var el = $j(this);
	    $j('.quick-move-in>li.activeslide').removeClass('activeslide');
	    var width = $j(window).width();
	    if (width < 1000) {
	        return;
	    }
        el.find('.cycle-slideshow').cycle('resume');
		setTimeout(function(){
			el.find('.cycle-slideshow').cycle('next');
		}, 1000);

        el.addClass('activeslide');
	});
	$j(document).on('mouseleave', '.quick-move-in>li', function() {
	    var width = $j(window).width();
	    if (width < 900) {
	        return;
	    }
	    $j('.quick-move-in>li.activeslide ul.cycle-slideshow').cycle('pause');
		$j('.quick-move-in>li.activeslide').removeClass('activeslide')
	});

    $j(document).on('click', '.launch-gallery', function(e){
       // e.preventDefault();
    });

    if ( !! $j('body.single-floorplan').length ) {

        $j('.home-features>div').hoverdir();
        $j('.cycle-slideshow').on('cycle-before', function(event, opts){
            $j('li.cycle-sentinel a').removeAttr('data-fancybox');
        });
        $j('.sub-nav .nav li a').click(function(){
            var el = $j(this);
            var label = el.text();
            var menu = el.text();
            $j('.sub-nav .toolbar button.active').click();
            $j('.sub-nav .toolbar button.current span').html(label);
            if ( label == 'Floorplan') {
                $j('.sub-nav .toolbar button.active').removeClass('active');
                $j('.sub-nav ul.tools').addClass('collapsed');
            }
        });
        $j('.sub-nav .tools li a').click(function(){
            var el = $j(this);
            var label = el.text();
            $j('.sub-nav .toolbar button.active').removeClass('active');
            $j('.sub-nav ul.tools').addClass('collapsed');
            if ( label == 'Floorplan') {
                $j('.sub-nav .nav li a[href="#floorplan"]').click();
                return false;
                $j('.sub-nav .toolbar button.active').removeClass('active');
                $j('.sub-nav ul.tools').addClass('collapsed');
            }
        });
        $j('.sub-nav .floorplans li a').click(function(e){
            var el = $j(this);
            var label = el.text();
            $j('.sub-nav .toolbar button.active').removeClass('active');
            $j('.sub-nav .floorplans li.active').removeClass('active');
            el.parent().addClass('active');
            $j('.sub-nav .floorplans').fadeOut(300);
        });
        $j('.sub-nav .toolbar button').click(function(){
            var el = $j(this);
            var menu = el.data('menu');
            $j('.sub-nav .toolbar button').removeClass('active');
            $j('.sub-nav .toolbar button.current').find('i.fas').addClass('fa-chevron-down').removeClass('fa-chevron-up');
            if ( menu == 'floorplans' ) {
                $j('.sub-nav ul').addClass('collapsed');
                $j('.sub-nav .nav li a[href="#similar-homes"]').click();
                return;
            }
            if ( menu == 'nav' && $j('.sub-nav ul.'+menu).hasClass('collapsed')) {
                el.find('i.fas').removeClass('fa-chevron-down').addClass('fa-chevron-up');
            } else {
                el.find('i.fas').addClass('fa-chevron-down').removeClass('fa-chevron-up');
            }
            if ( $j('.sub-nav ul.'+menu).hasClass('collapsed')) {
                $j('.sub-nav ul').addClass('collapsed');
                $j('.sub-nav ul.'+menu).removeClass('collapsed');
                el.addClass('active');
            } else {
                $j('.sub-nav  ul').addClass('collapsed');
            }
        });
        $j('.btn-start-ifp').click(function(event){
            event.preventDefault();
            var el = $j(this);
            var iframe =  $j('.fp-interactive').data('src');
            $j('html').addClass('disableScroll');
            $j('.fp-interactive').fadeIn(300).addClass('ifp-open').append('<iframe src="'+iframe+'" width="100%" height="100%" border="0"></iframe>');
            return false;
        });

        $j('.btn-close-ifp').click(function(event){
            event.preventDefault();
            $j('html').removeClass('disableScroll');
            $j('.fp-interactive').fadeOut(300).removeClass('ifp-open');
            $j('.fp-interactive iframe').remove();
            return false;
        });

        $j('.fp-nav a:not(.btn-fp-zoom').click(function(event) {
            event.preventDefault();
            var el = $j(this);
            var target = el.attr('href');
            $j('.fp-nav>ul>li:not(.zoomer)').find('a.active').removeClass('active');
            el.addClass('active');
            $j('.floorplan div.level').addClass('hidden-fp');
            $j(target).removeClass('hidden-fp');
            bLazy.revalidate();
            return false;
        });

        $j('.fp-nav a.btn-fp-zoom').click(function(event) {
            event.preventDefault();
            var el = $j(this);
            $j('.level:not(.hidden-fp) a').click();
            window.location.hash = 'zoom';
            return;
            log($j('#msizer').width());
            if ( $j('#msizer').width() < '10') {
                $j('.level:not(.hidden-fp) a').click();
                return;
            }
            if ( el.hasClass('active') ) {
                el.removeClass('active');
                el.find('.fad').addClass('fa-search-plus').removeClass('fa-search-minus');
            } else {
                $j('a.btn-fp-zoom.active').removeClass('active');
                el.addClass('active');
                el.find('.fad').removeClass('fa-search-plus').addClass('fa-search-minus');
            }
        });

        $j(document).bind("keyup", function (event) {
            if (event.keyCode == 27 ) {
                $j('.btn-close-ifp').click();
            }
        });

        $j('.btn-fpz-close').click(function(event){
            event.preventDefault();
            $j('html.disableScroll').removeClass('disableScroll');
            return false;
        });


        $j('.fp-zoom').click(function(event){
            event.preventDefault();
            $j('.akel-fpz').remove();
            $j('html').addClass('disableScroll');
            $j('.level:not(.hidden-fp) .zoomHolder .fullscreenToggle:first').click();
            $j('.fullscreenDiv').append('<div class="akel-fpz"><a class="btn btn-primary btn-fpz btn-sm start"><span class="fas fa-chevron-left"></span> Go Back</a></div>');

            if ( $j('.fp-nav li.floor').length > 1 ) {
                $j('.akel-fpz-switcher').html('');
                $j('.akel-fpz').append('<div class="akel-fpz-switcher started"></div>')
                $j('.fp-nav li.floor').each(function(){
                    $j('.akel-fpz-switcher.started').append($j(this).html());
                });
                $j('.akel-fpz-switcher').removeClass('started');
                $j('.akel-fpz-switcher a').addClass('btn btn-primary btn-sm');
            }

            setTimeout(function(){
                $j('.btn-sm.btn-fpz.start').removeClass('start');
            }, 500);

        });

        $j(document).on('click', '.btn-fpz', function (event) {
            event.preventDefault();
            $j('.fullscreenToggle.on').click();
        });


        $j(document).on('click', '.akel-fpz-switcher a', function (event) {
            event.preventDefault();
            var el = $j(this);
            $j('.fullscreenToggle.on').click();
            var target = el.data('target');
            $j('.fp-nav>ul>li.'+target+' a').click();
            $j('.fp-zoom').click();
        });

        if ( window.location.hash == '#zoom' ) {
            setTimeout(function(){
                $j('.btn-fp-zoom').click();
            },100);
        }
    }
}
