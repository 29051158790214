function calendarWidget() {
    $j('div.calendar-widget').each(function () {
        let widget = $j(this);
        let closeTimer;
        widget.find('button').click(function (e) {
            e.preventDefault();
            let el = $j(this);
            let wrap = el.hasClass('calbtn') ? el.closest('.calendar-widget') : el.parent();
            let options = wrap.find('.options');
            if (wrap.hasClass('active')) {
                options.stop(false, true).fadeOut(250);
                wrap.removeClass('active').attr('aria-expanded', 'false');
                el.focus();
            } else {
                $j('div.calendar-widget.active').removeClass('active').attr('aria-expanded', 'false').find('.options').stop(false, true).fadeOut(150);
                options.stop(false, true).fadeIn(150);
                wrap.addClass('active').attr('aria-expanded', 'true');
                //options.find('a').first().focus();
            }
        });
        widget.hover(
            function () {
                clearTimeout(closeTimer);
            },
            function () {
                let widget = $j(this);
                closeTimer = setTimeout(function () {
                    widget.removeClass('active').attr('aria-expanded', 'false').find('.options').stop(false, true).fadeOut(150);
                }, 1200);
            }
        );
        $j(document).click(function (e) {
            if (!$j(e.target).closest('div.calendar-widget').length) {
                $j('div.calendar-widget.active').removeClass('active').attr('aria-expanded', 'false').find('.options').stop(false, true).fadeOut(150).closest('.calendar-widget').find('button').focus();
            }
        });
        $j(document).keydown(function (e) {
            if (e.key === "Escape") {
                $j('div.calendar-widget.active').removeClass('active').attr('aria-expanded', 'false').find('.options').stop(false, true).fadeOut(150).closest('.calendar-widget').find('button').focus();
            }
        });
        widget.find('button').on('focus', function () {
            clearTimeout(closeTimer);
        });
    });
}
